import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator  } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCVdBh8VrQx8W6j_fPU8A7H6vRBEqAXyKA",
    authDomain: "notepet-certs-portal-debug.firebaseapp.com",
    projectId: "notepet-certs-portal-debug",
    storageBucket: "notepet-certs-portal-debug.appspot.com",
    messagingSenderId: "602762064458",
    appId: "1:602762064458:web:32122b2d763fcd6357ecb3",
    measurementId: "G-FM3GPX0C8F"
};

const app = initializeApp(firebaseConfig);

export class FirebaseWrapper {

    static getFunctions() {
        const functions = getFunctions(app);
        // connectFunctionsEmulator(functions, "localhost", 5001);
        return functions
    }

}