import { Card, Container, Grid, Paper, Typography } from "@mui/material";

export function HomePage(props: any) {

    return <Container maxWidth="md" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h5">Notepet certificate portal</Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>In this portal, you can create certificates.</Typography>

        {/* <Typography variant="h5" align="center">Benefits</Typography> */}

        {/* <Grid container spacing={2}>
            <Grid item sm={4}>
                <Card sx={{padding: 1}}>
                    <Typography>Verifiable</Typography> 
                </Card>
            </Grid>

            <Grid item sm={4}>
                <Card sx={{padding: 1}}>
                    <Typography>Portable</Typography> 
                </Card>
            </Grid>

            <Grid item sm={4}>
                <Card sx={{padding: 1}}>
                    <Typography>Easy</Typography> 
                </Card>
            </Grid>
        </Grid> */}

    </Container>
}