import { createTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import './App.css';
import "@fontsource/poppins";

import { ThemeProvider } from '@mui/system';
import { VaccinationForm } from './VaccinationForm';
import { MockCertificatePage, RenderCertificatePage } from './RenderCertificatePage';
import { HomePage } from './HomePage';
import { DownloadCertificatePage } from './DownloadCertificatePage';


const theme = createTheme({
    typography: {
        fontFamily: "Poppins"
    },
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "/new-certificate",
        element: <VaccinationForm />,
    },
    {
        path: "/download-certificate",
        element: <DownloadCertificatePage />,
    },
    {
        path: "/render/",
        element: <RenderCertificatePage />
    },
    {
        path: "/mock-render/",
        element: <MockCertificatePage/>
    }
]);

function App() {

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
