import { Button, Container, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

export function DownloadCertificatePage(){
    
    const navigate = useNavigate();
    const locationState = useLocation();

    const [signedCertLink, setSignedCertLink] = useState<string|null>(null);
    const [signedCertFileName, setSignedCertFileName] = useState("");

    useEffect(() => {
        if (locationState.state){
            const signedDocument = locationState.state.signedDocument;

            // use the document hash as the filename
            const documentHash = signedDocument.signature.targetHash;
            const fileName = "notepet-vaccination-cert-" + documentHash.substring(0, 10) + "-" + documentHash.substring(
                documentHash.length-10) + ".oa";
            setSignedCertFileName(fileName);

            setSignedCertLink("data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(signedDocument)));
        } else {
            navigate("/");
        }
    }, []);


    return <Container maxWidth="sm">
        <Paper elevation={1}
                sx={{ marginY: { xs: 3, md: 6 }, padding: { xs: 2, md: 3 } }}
            >

            <Typography variant="h5">Certificate generated</Typography>
            <Typography sx={{marginBottom: 2}}>The certificate was successfully generated.</Typography>

            {signedCertLink && <Button variant="contained" href={signedCertLink} target="_blank" rel="noreferrer" download={signedCertFileName}>Download Certificate</Button>}

            <Button sx={{marginLeft: 2}} variant="contained" onClick={() => {
                navigate("/new-certificate");
            }}>Create new certificate</Button>

        </Paper>
    </Container>

}