import { Typography, Grid, TextField, Box, Button, Container, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { DateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";

import { httpsCallable } from 'firebase/functions';
import { FirebaseWrapper } from "./FirebaseWrapper";

import { useNavigate } from 'react-router-dom';

export function VaccinationForm() {

    const navigate = useNavigate();

    const [petName, setPetName] = useState("");
    const [petMicrochipID, setPetMicrochipID] = useState("");

    const [vaccineName, setVaccineName] = useState("");
    const [vaccineDoseAmount, setVaccineDoseAmount] = useState(0);

    const [vaccineManufacturer, setVaccineManufacturer] = useState("");
    const [vaccineLotNumber, setVaccineLotNumber] = useState("");

    const [vaccineDoseInSeries, setVaccineDoseInSeries] = useState<number>(0);
    const [vaccineDoseTotalInSeries, setVaccineDoseTotalInSeries] = useState<number>(0);

    const [vaccineGiven, setVaccineGiven] = useState(new Date());

    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyRegistration, setCompanyRegistration] = useState("");

    const [isGeneratingCert, setIsGeneratingCert] = useState(false);

    // error validation states
    const [petNameError, setPetNameError] = useState<string | null>(null);
    const [petMicrochipIDError, setPetMicrochipIDError] = useState<string | null>(null);

    const [vaccineNameError, setVaccineNameError] = useState<string|null>(null);
    const [vaccineManufacturerError, setVaccineManufacturerError] = useState<string|null>(null);

    const [vaccineLotNumberError, setVaccineLotNumberError] = useState<string|null>(null);

    const [companyNameError, setCompanyNameError] = useState<string|null>(null);
    const [companyAddressError, setCompanyAddressError] = useState<string|null>(null);
    const [companyRegistrationError, setCompanyRegistrationError] = useState<string|null>(null);

    function validateFields() {
        // Return true if form data is validated.

        let tmpPetNameError: string | null = null;
        let tmpPetMicrochipIDError: string | null = null;

        let tmpVaccineNameError: string | null = null;
        let tmpVaccineManufacturerError: string | null = null;
        let tmpVaccineLotNumberError: string | null = null;

        let tmpCompanyNameError: string | null = null;
        let tmpCompanyAddressError: string | null = null;
        let tmpCompanyRegistrationError: string | null = null;

        if (petName.trim().length === 0) {
            tmpPetNameError = "Please enter pet name.";
        }
        setPetNameError(tmpPetNameError);

        // accept either a 10 digit or a 15 digit microchip ID
        const validMicrochipID = /^\d{10}(\d{5})?$/;
        const match = petMicrochipID.match(validMicrochipID);
        if (match === null) {
            tmpPetMicrochipIDError = "Please enter valid microchip number.";
        }
        setPetMicrochipIDError(tmpPetMicrochipIDError);

        if (vaccineName.trim().length === 0) {
            tmpVaccineNameError = "Please enter vaccine name.";
        }
        setVaccineNameError(tmpVaccineNameError);

        if (vaccineManufacturer.trim().length === 0) {
            tmpVaccineManufacturerError = "Please enter vaccine manufacturer.";
        }
        setVaccineManufacturerError(tmpVaccineManufacturerError);

        if (vaccineLotNumber.trim().length === 0) {
            tmpVaccineLotNumberError = "Please enter vaccine lot number.";
        }
        setVaccineLotNumberError(tmpVaccineLotNumberError);

        if (companyName.trim().length === 0) {
            tmpCompanyNameError = "Please enter clinic name.";
        }
        setCompanyNameError(tmpCompanyNameError);

        if (companyAddress.trim().length === 0) {
            tmpCompanyAddressError = "Please enter clinic address.";
        }
        setCompanyAddressError(tmpCompanyAddressError);

        if (companyRegistration.trim().length === 0) {
            tmpCompanyRegistrationError = "Please enter clinic registration number.";
        }
        setCompanyRegistrationError(tmpCompanyRegistrationError);

        return tmpPetNameError === null &&
            tmpPetMicrochipIDError === null &&
            
            tmpVaccineNameError === null && 
            tmpVaccineManufacturerError === null &&
            tmpVaccineLotNumberError === null &&

            tmpCompanyNameError === null &&
            tmpCompanyAddressError === null && 
            tmpCompanyRegistrationError === null
        ;
    }

    function validateAndGenerate() {
        if (isGeneratingCert) {
            return;
        }

        const validationResult = validateFields();
        if (!validationResult){
            return;
        }

        const document = {
            pet: {
                name: petName,
                microchipID: petMicrochipID
            },
            vaccine: {
                name: vaccineName,
                doseAmount: vaccineDoseAmount,
                manufacturer: vaccineManufacturer,
                lotNumber: vaccineLotNumber,

                doseInSeries: vaccineDoseInSeries,
                totalInSeries: vaccineDoseTotalInSeries,
            },
            performedBy: {
                timestamp: vaccineGiven.toISOString(),
                company: {
                    name: companyName,
                    address: companyAddress,
                    registrationID: companyRegistration
                }
            },
        };

        // clear first
        setIsGeneratingCert(true);

        const functions = FirebaseWrapper.getFunctions();
        const generateVaccinationCertificate = httpsCallable(functions, 'generateVaccinationCertificate');
        return generateVaccinationCertificate({ document }).then(result => {
            const signedDocument = result.data;
            navigate("/download-certificate", {
                state: {
                    signedDocument
                }
            });
        });
    }

    return (
        <Container maxWidth="sm">
            <Paper elevation={1}
                sx={{ marginY: { xs: 3, md: 6 }, padding: { xs: 2, md: 3 } }}
            >

                <Typography component="h2" variant="h5" align="center" sx={{ paddingBottom: 4 }}>Vaccination certificate</Typography>

                <Typography variant="h6" gutterBottom>Pet details</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="petName"
                            name="petName"
                            label="Full Name"
                            fullWidth
                            variant="standard"
                            
                            error={petNameError !== null}
                            helperText={petNameError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={petName}
                            onChange={(event) => {
                                setPetName(event.target.value);
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="petMicrochipID"
                            name="petMicrochipID"
                            label="Microchip ID"
                            fullWidth
                            variant="standard"

                            error={petMicrochipIDError !== null}
                            helperText={petMicrochipIDError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={petMicrochipID}
                            onChange={(event) => {
                                setPetMicrochipID(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" sx={{ marginTop: 4 }}>Vaccine</Typography>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField
                            required
                            id="vaccineName"
                            name="vaccineName"
                            label="Vaccine name"
                            fullWidth
                            variant="standard"

                            error={vaccineNameError !== null}
                            helperText={vaccineNameError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={vaccineName}
                            onChange={event => {
                                setVaccineName(event.target.value);
                            }} />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            required
                            id="vaccineManufacturer"
                            name="vaccineManufacturer"
                            label="Manufacturer"
                            fullWidth
                            variant="standard"
                            
                            error={vaccineManufacturerError !== null}
                            helperText={vaccineManufacturerError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={vaccineManufacturer}
                            onChange={event => {
                                setVaccineManufacturer(event.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            required
                            id="vaccineLotNumber"
                            name="vaccineLotNumber"
                            label="Lot number"
                            fullWidth
                            variant="standard"

                            error={vaccineLotNumberError !== null}
                            helperText={vaccineLotNumberError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={vaccineLotNumber}
                            onChange={event => {
                                setVaccineLotNumber(event.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            required
                            id="vaccineDoseAmount"
                            name="vaccineDoseAmount"
                            label="Dose amount"
                            fullWidth
                            variant="standard"
                            type="number"
                            value={vaccineDoseAmount}
                            onChange={event => {
                                setVaccineDoseAmount(Number(event.target.value));
                            }}
                        />
                    </Grid>

                    <Grid item sm={9}></Grid>

                    <Grid item sm={3}>
                        <TextField
                            required
                            id="vaccineDoseInSeries"
                            name="vaccineDoseInSeries"
                            label="Dose in series"
                            fullWidth
                            variant="standard"
                            type="number"
                            value={vaccineDoseInSeries}
                            onChange={event => {
                                setVaccineDoseInSeries(Number(event.target.value));
                            }}
                        />
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            required
                            id="vaccineDoseTotalInSeries"
                            name="vaccineDoseTotalInSeries"
                            label="Total in series"
                            fullWidth
                            variant="standard"
                            type="number"
                            value={vaccineDoseTotalInSeries}
                            onChange={event => {
                                setVaccineDoseTotalInSeries(Number(event.target.value));
                            }}
                        />
                    </Grid>

                    <Grid item sm={6}></Grid>

                    <Grid item sm={6}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Given on"

                            readOnly={isGeneratingCert}
                            disableFuture={true}

                            value={vaccineGiven}
                            onChange={(newValue) => {
                                if (newValue !== null) {
                                    setVaccineGiven(newValue);
                                }
                            }}
                        />
                    </Grid>
                </Grid>


                <Typography variant="h6" sx={{ marginTop: 4 }}>Performed at Clinic</Typography>

                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField
                            required
                            id="companyName"
                            name="companyName"
                            label="Clinic name"
                            fullWidth
                            variant="standard"

                            error={companyNameError !== null}
                            helperText={companyNameError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={companyName}
                            onChange={event => {
                                setCompanyName(event.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            required
                            id="companyRegistration"
                            name="companyRegistration"
                            label="Clinic registration number"
                            fullWidth
                            variant="standard"
                            
                            error={companyRegistrationError !== null}
                            helperText={companyRegistrationError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={companyRegistration}
                            onChange={event => {
                                setCompanyRegistration(event.target.value);
                            }} />
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            required
                            id="companyAddress"
                            name="companyAddress"
                            label="Clinic address"
                            fullWidth
                            multiline={true}
                            rows={5}
                            variant="standard"

                            error={companyAddressError !== null}
                            helperText={companyAddressError}

                            InputProps={{
                                readOnly: isGeneratingCert,
                            }}

                            value={companyAddress}
                            onChange={event => {
                                setCompanyAddress(event.target.value);
                            }} />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 4 }}>
                    <LoadingButton
                        variant="contained"
                        loading={isGeneratingCert}
                        onClick={validateAndGenerate}>Generate certificate</LoadingButton>

                </Box>
            </Paper>
        </Container>
    )
}
