import { FramedDocumentRenderer, TemplateRegistry } from "@govtechsg/decentralized-renderer-react-components";
import { v2 } from "@govtechsg/open-attestation";
import { Container, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";

interface VaccinationTemplateCertificate extends v2.OpenAttestationDocument {
    pet: {
        name: string,
        microchipID: string,
    },
    vaccine: {
        name: string,
        manufacturer: string,
        lotNumber: string,
    },
    performedBy: {
        timestamp: string,
        company: {
            name: string,
            registrationID: string,
            address: string,
        }
    },

    issuedBy: {
        name: string,
        url: string,
        timestamp: string,
    }
}

function VaccinationCertV1Template(props: { document: VaccinationTemplateCertificate }) {
    const { document } = props;

    const givenDate = new Date(document.performedBy.timestamp);
    const formattedDate = dayjs(givenDate).format('D MMMM YYYY, h:mmA');

    const certificateIssuedDate = new Date(document.issuedBy.timestamp);
    const formattedCertIssueDate = dayjs(certificateIssuedDate).format('D MMMM YYYY, h:mmA');

    const companyAddress = document.performedBy.company.address.split("\n").map((item, index) => {
        return <Typography key={'address-line-' + index}>{item}</Typography>
    });
    
    return (
        <Container maxWidth="md" sx={{ marginTop: 2, marginBottom: 2 }}>
            <Paper sx={{ padding: 8 }}>
                <Typography variant="h4" align="center" sx={{ paddingBottom: 4 }}>Vaccination Certificate</Typography>

                <Typography>This certifies that <b>{document?.pet?.name}</b> (Microchip: {document?.pet?.microchipID}) was given the following vaccine: <b>{document.vaccine.name}</b> (Manufacturer: {document.vaccine.manufacturer}, lot number: {document.vaccine.lotNumber}) on {formattedDate}.</Typography>

                <Typography sx={{ paddingTop: 2 }}>The vaccination was performed at the following clinic:</Typography>
                
                <Typography sx={{ paddingTop: 2 }}>{document.performedBy.company.name} (Registration: {document.performedBy.company.registrationID})</Typography>
                <Typography>{companyAddress}</Typography>

                
                <Grid container alignItems="center" sx={{paddingTop: 4}}>

                    <Grid item sm={2}
                        sx={{
                            backgroundImage: 'url(' + require('./assets/logo.png') +')',
                            width: 100,
                            height: 100,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></Grid>        

                    <Grid item>
                        <Typography>Created with Notepet.io</Typography>
                        <Typography>This certificate was generated on {formattedCertIssueDate}</Typography>
                    </Grid>
                </Grid>

            </Paper>
        </Container>
    )
}

const VaccinationCertV1Templates = [
    {
        id: 'vaccination-certificate-v1',
        label: 'Vaccination certificate v1',
        template: VaccinationCertV1Template,
    }
];
export function RenderCertificatePage() {

    const registeredTemplates: TemplateRegistry<VaccinationTemplateCertificate> = {
        "vaccination-certificate-v1": VaccinationCertV1Templates
    };

    return <Container>
        <FramedDocumentRenderer templateRegistry={registeredTemplates} />
    </Container>
}

export function MockCertificatePage() {

    const performedTimestamp = "2022-12-03T13:00:00.000Z";
    const generateTimestamp = "2022-12-03T13:00:00.000Z";

    const sampleDoc: VaccinationTemplateCertificate = {
        pet: {
            name: "Doggy",
            microchipID: "123123123",
        },
        vaccine: {
            name: "FluVax",
            manufacturer: "MedCorp",
            lotNumber: "123123",
        },
        performedBy: {
            timestamp: performedTimestamp,
            company: {
                name: 'Clinic A',
                address: 'Clinic A\nSingapore Road 5\nSomewhere Place\nSingapore 123456',
                registrationID: '123123123',
            }
        },

        issuedBy: {
            name: 'Notepet',
            url: 'https://dev.certs.notepet.io',
            timestamp: generateTimestamp,
        },

        issuers: [
            {
                id: '0x123',
                name: 'Notepet certificate portal',
                revocation: {
                    type: v2.RevocationType.OcspResponder,
                    location: 'https://dev.certs.notepetio./ocsp',
                },
                identityProof: {
                    type: v2.IdentityProofType.DNSDid,
                    location: 'dev.certs.notepet.io',
                    key: '0x123',
                }
            }
        ]
    };

    return <VaccinationCertV1Template document={sampleDoc} />
}